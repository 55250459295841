/*
This is a Navigation bar to allow the users to navigate the application.
It will be modified to be mobile friendly, and use a style desired by the CoCoRaHS group in Bolder, CO
*/

import React from 'react';
import {NavLink} from 'react-router-dom';



class NavBar extends React.Component{
    state={
        api:"",
        curl:false,
        python:false,
        r:false,
        matlab:false,
        java:false,
        javascript:false,
    }
    componentDidMount(){
        var href = window.location.href;
        if (href.indexOf("/curl")!==-1){
            this.setState({api:0,curl:true});
        }
        if (href.indexOf("/python")!==-1){
            this.setState({api:0,python:true});
        }
        if (href.indexOf("/r")!==-1){
            this.setState({api:0,r:true});
        }
        if (href.indexOf("/matlab")!==-1){
            this.setState({api:0,matlab:true});
        }
        if (href.indexOf("/java")!==-1){
            this.setState({api:0,java:true});
        }
        if (href.indexOf("/js")!==-1){
            this.setState({api:0,javascript:true});
        }
    }
    onClick(value){
        switch (value) {
            case "api":
                if (this.state.api===0){
                    this.setState({api:""})
                }
                else{
                    this.setState({api:0})
                }
                break;
        
            default:
                break;
        }
    }
    clicked(){
        this.setState({
            curl:false,
            python:false,
            r:false,
            matlab:false,
            java:false,
            javascript:false,
        })
    }
    render(){
        return(
            <div className="AppBar">
                <div className="title-div">
                    CoCoRaHS <br/> API
                </div>
                
                <div className="navdiv">
                    <NavLink exact to='/' className="link" activeClassName="active" onClick={this.clicked.bind(this)}>
                        About
                    </NavLink>
                    <div className= "alink" onClick={this.onClick.bind(this,"api")}>
                        API
                    </div>
                    <div className="smooth" style={this.state.api===0? {display:"flex",flexDirection:"column"}:{height:"0px",display:"none"}}>
                        <NavLink exact to='/curl' className="link" activeClassName="active" style={{textAlign:"right",fontSize:"20px"}} >
                            <div style={{paddingRight:"20px"}}>cURL</div>
                        </NavLink>
                        <NavLink exact to='/python' className="link" activeClassName="active" style={{textAlign:"right",fontSize:"20px"}} >
                            <div style={{paddingRight:"20px"}}>Python</div>
                        </NavLink>
                        <NavLink exact to='/r' className="link" activeClassName="active" style={{textAlign:"right",fontSize:"20px"}} >
                            <div style={{paddingRight:"20px"}}>R</div>
                        </NavLink>
                        <NavLink exact to='/matlab' className="link" activeClassName="active" style={{textAlign:"right",fontSize:"20px"}} >
                            <div style={{paddingRight:"20px"}}>MATLAB</div>
                        </NavLink>
                        <NavLink exact to='/java' className="link" activeClassName="active" style={{textAlign:"right",fontSize:"20px"}} >
                            <div style={{paddingRight:"20px"}}>Java</div>
                        </NavLink>
                    </div>
                    <NavLink to='/reference' className="link" activeClassName="active" onClick={this.clicked.bind(this)}>
                        Reference
                    </NavLink>
                    <NavLink to='/faq' className="link" activeClassName="active" onClick={this.clicked.bind(this)}>
                        FAQ
                    </NavLink>
                </div>
                <a href="https://cocorahs.org/" target="_blank" rel="noopener noreferrer" className="cocorahs"><img src={require('../cocorahs.png')} alt="logo" height="100" width="100" /></a>
            </div>
        )
    }
}



export default NavBar