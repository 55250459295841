/*
This page will be a FAQ page that answers questions that have been asked by users during the user testing phase of the application.
It will also contain information on how to contact CoCoRaHS technical team.
*/

import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {

}

class FAQ extends React.Component{
    render(){
        return(
            <Typography>This is the FAQ Page</Typography>
        )
    }
}

FAQ.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQ)