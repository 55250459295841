/*
This page will operate as the home page for the application. It will be the about page, 
and include examples uses for the data, and how the API should be used.
*/

import React from 'react';
import text from '../content/Home.json'


class Home extends React.Component{
    render(){
        return(
            <div className="Home">
                <p>{text.intro}</p>
                <p>Please contact <a href='mailto:julian@colostate.edu' >julian@colostate.edu</a> with any questions.</p>
                <p>{text.tag}
                <br /><a href="{text.endpoint}" >{text.endpoint}</a></p>
            </div>
        )
    }
}


export default Home