/*
This is the container for all the rerouted pages in the application.
*/

import React from 'react';
import {Route} from 'react-router-dom';
import Home from './home';
import {CURL,Java,Python,MATLAB,R} from './examples';
import Reference from './reference';
import FAQ from './faq';
import API from './api';

function Body(){
    return(
        <div>
            <Route exact path='/' component={Home}/>
            <Route exact path='/curl' component={CURL}/>
            <Route exact path='/java' component={Java}/>
            <Route exact path='/python' component={Python}/>
            <Route exact path='/matlab' component={MATLAB}/>
            <Route exact path='/r' component={R}/>
            <Route exact path='/reference' component={Reference}/>
            <Route exact path='/faq' component={FAQ}/>
        </div>
    )
}



export default Body