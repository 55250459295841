/*
This page will show the information necessary for data users to access the CoCoRaHS data.
Example queiries will be given and code will be shown for cURL, JavaScript, Java, R, and Python.
*/

import React from 'react';
import cdata from '../content/code.json';
import {Select,MenuItem} from '@material-ui/core';
import {CURL,Java,Python,MATLAB,R} from './examples';
const code = cdata;


class API extends React.Component{
    state ={
        value:"0"
    }
    selected(e){
        this.setState({value:e.target.value});
    }
    render(){
        return(
            <div className="api">
                {/* <div className="sidebar">
                <a
                    className="api-link"
                    href="#introduction"
                >Introduction</a>
                <a
                    className="api-link"
                    href="#section1"
                >Section 1</a>
                <a
                    className="api-link"
                    href="#section2"
                >Section 2</a>
                </div> */}
                <div className="example">
                   <div className="introduction">
                    
                    <br/>
                    <Select className="select-css" disableUnderline={true} value={this.state.value} onChange={this.selected.bind(this)} variant="outlined">
                        <MenuItem value="0">cURL</MenuItem>
                        <MenuItem value="1">Python</MenuItem>
                        <MenuItem value="2">R</MenuItem>
                        <MenuItem value="3">MATLAB</MenuItem>
                        <MenuItem value="4">Java</MenuItem>
                        <MenuItem value="5">JavaScript</MenuItem>
                    </Select>
                   </div>
                   <CURL/>
                   <div style={this.state.value==="0"?{}:{display:"none"}}><Python/></div>
                   <R style={this.state.value==="0"?{}:{display:"none"}}/>
                   <MATLAB style={this.state.value==="0"?{}:{display:"none"}}/>
                   <Java style={this.state.value==="0"?{}:{display:"none"}}/>
                </div>
            </div>
        )
    }
}



export default API