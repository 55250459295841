import React, { Component } from 'react';
import NavBar from './components/appbar';
import Body from './components/body';
import Login from './components/login';

class App extends Component {
  render() {
    return (
      <div>
        {localStorage.getItem("cocorahsapi")===null?
          <Login/>
          :
          <>
          <NavBar/>
          <Body/>
          </>
        }
      </div>
    );
  }
}

export default App;
