import React from "react";

class Login extends React.Component{
    state={
        username:"",
        password:"",
        correctpassword:"cocorahsapi",
        correctusername:"datauser",
    }
    verify(){
        var check=true
        if(this.state.username===""){
            check=false
            document.getElementById("username").style.border="1px solid red"
        }
        if(this.state.password===""){
            check=false
            document.getElementById("password").style.border="1px solid red"
        }
        if(this.state.password!==this.state.correctpassword){
            check=false
            document.getElementById("password").style.border="1px solid red"
            document.getElementById("p-error").style.visibility="visible"
        }
        if(this.state.username!==this.state.correctusername){
            check=false
            document.getElementById("username").style.border="1px solid red"
            document.getElementById("u-error").style.visibility="visible"
        }
        if(check){
            this.onSubmit()
        }
    }
    onSubmit(){
        localStorage.setItem("cocorahsapi","loggedin")
        window.location.reload();
    }
    onChange(event){
        document.getElementById(event.target.id).style.border="1px solid rgba(0,0,0,.4)"
        if (event.target.id){
            document.getElementById("p-error").style.visibility="hidden"
        }
        this.setState({[event.target.id]:event.target.value})
    }
    render(){
        return(
            <div className="login">
                <div className="login-container">
                    <p className="login-text">Username</p>
                    <input type="text" className="login-input" id="username" value={this.state.username} onChange={this.onChange.bind(this)}/>
                </div>
                <p style={{fontSize:"15px",color:"red",visibility:"hidden"}} id="u-error">Username Incorrect</p>
                <div className="login-container">
                    <p className="login-text">Password</p>
                    <input type="password" className="login-input" id="password" value={this.state.password} onChange={this.onChange.bind(this)}/>
                </div>
                <p style={{fontSize:"15px",color:"red",visibility:"hidden"}} id="p-error">Password Incorrect</p>
                <div className="login-submit" onClick={this.verify.bind(this)}>Submit</div>
            </div>
        )
    }
}

export default Login