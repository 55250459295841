/*
This page will be a reference page that shows in detail the calls able to be requested from the api.
It will be linkable from the API page, but also be stand-alone. 
*/

import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {

}

class Reference extends React.Component{
    render(){
        return(
            <Typography>This is the Reference Page</Typography>
        )
    }
}

Reference.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reference)