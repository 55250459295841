import React from 'react';
import info from '../content/code.json';
 

function CURL(){
    return(
        <div className="api">
            <div className="sidebar">
                <div style={{width:"100%",marginBottom:"8px"}}><a
                    className="api-link"
                    href="#reports"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Reports</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section1"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section2"
                >Report Type</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section3"
                >{'Date & Time'}</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section4"
                >Location</a></div>
                <div style={{width:"100%",marginBottom:"8px",marginTop:"8px"}}><a
                    className="api-link"
                    href="#stations"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Stations</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section5"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section6"
                >Location</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section7"
                >Station Date Type</a></div>
            </div>            
            <div className="introduction">cURL</div>
            <div className="introduction-text">{info.introduction.curl}</div>
            <div className="introduction-divider"></div>
            <div className="introduction" style={{fontSize:"60px"}} id="reports">Reports</div>
            <div className="introduction-text">This section provides the queries available for reports.</div>
            <div className="example-end"></div>
            <div className="example" id="section1">
                <div className="example-title">{info.section1.title}</div>
                <div className="example-text">{info.section1.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=XML</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section1.end}</div>
            </div>
            <div className="example" id="section2">
                <div className="example-title">{info.section2.title}</div>
                <div className="example-text">{info.section2.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=csv"&"reporttype=hail</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section2.end}</div>
            </div>
            <div className="example" id="section3">
                <div className="example-title">{info.section3.title}</div>
                <div className="example-text">{info.section3.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail"&"startdate=4/01/2011"&"enddate=4/30/2011</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail"&"date=4/01/2011%208:00%20AM</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail"&"date=4/01/2011"&"timesingmt=true</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text4}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail"&"date=4/01/2011"&"dtf=2</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            <div className="example" id="section4">
                <div className="example-title">{info.section4.title}</div>
                <div className="example-text">{info.section4.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2018"&"enddate=5/31/2018"&"country=usa"&"state=GA","AL</span>
                        </span>
                    </pre>
                </div>
                {/* <div className="example-end">{info.section4.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <code className="language-java">
                        {`cURL -o [filename] http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2019"&"enddate=5/31/2019"&"sw=32.33","-97.56"&"ne=32.23","96.31`}
                        </code>
                    </pre>
                </div> */}
                <div className="example-end">{info.section4.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportreports.aspx?date=6/1/2019"&"stations=CO-LR-273","CO-LR-284</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end"></div>
                <div className="introduction" style={{fontSize:"60px"}} id="stations">Stations</div>
                <div className="introduction-text">This section provides the queries available for stations.</div>
                <div className="example-end"></div>
                <div className="example" id="section5">
                <div className="example-title">{info.section5.title}</div>
                <div className="example-text">{info.section5.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=xml</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section5.end}</div>
            </div>
            <div className="example" id="section6">
                <div className="example-title">{info.section6.title}</div>
                <div className="example-text">{info.section6.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=csv"&"state=CA"&"county=LA</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section6.end}</div>
            </div>
            <div className="example" id="section7">
                <div className="example-title">{info.section7.title}</div>
                <div className="example-text">{info.section7.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa"&"stationdatetype=creationdate"&"startdate=3/1/2018"&"enddate=3/31/2018</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section7.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                        <span className="code-command">cURL</span> -o [filename] <span className="code-url">http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa"&"state=CO"&"stationdatetype=timestamp"&"date=8/16/2018%2005:00%20AM</span>
                        </span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            </div>
        </div>
    )
}

function Java(){
    return(
        <div className="api">
            <div className="sidebar">
                <div style={{width:"100%",marginBottom:"8px"}}><a
                    className="api-link"
                    href="#reports"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Reports</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section1"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section2"
                >Report Type</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section3"
                >{'Date & Time'}</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section4"
                >Location</a></div>
                <div style={{width:"100%",marginBottom:"8px",marginTop:"8px"}}><a
                    className="api-link"
                    href="#stations"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Stations</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section5"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section6"
                >Location</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section7"
                >Station Date Type</a></div>
            </div>            
            <div className="introduction">Java</div>
            <div className="introduction-text">{info.introduction.java}</div>
            <div className="introduction-divider"></div>
            <div className="introduction" style={{fontSize:"60px"}} id="reports">Reports</div>
            <div className="introduction-text">This section provides the queries available for reports.</div>
            <div className="example-end"></div>
            <div className="example" id="section1">
                <div className="example-title">{info.section1.title}</div>
                <div className="example-text">{info.section1.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=XML"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section1.end}</div>
            </div>
            <div className="example" id="section2">
                <div className="example-title">{info.section2.title}</div>
                <div className="example-text">{info.section2.text}</div>
                <div className="example-code">
                <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=csv&reporttype=hail"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section2.end}</div>
            </div>
            <div className="example" id="section3">
                <div className="example-title">{info.section3.title}</div>
                <div className="example-text">{info.section3.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&startdate=4/01/2011&enddate=4/30/2011"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011%208:00%20AM"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&timesingmt=true"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text4}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&dtf=2"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            <div className="example" id="section4">
                <div className="example-title">{info.section4.title}</div>
                <div className="example-text">{info.section4.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2018&enddate=5/31/2018&country=usa&state=GA,AL"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                {/* <div className="example-end">{info.section4.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <code className="language-java">
                        {`cURL -o [filename] http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2019"&"enddate=5/31/2019"&"sw=32.33","-97.56"&"ne=32.23","96.31`}
                        </code>
                    </pre>
                </div> */}
                <div className="example-end">{info.section4.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?date=6/1/2019&stations=CO-LR-273,CO-LR-284"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end"></div>
                <div className="introduction" style={{fontSize:"60px"}} id="stations">Stations</div>
                <div className="introduction-text">This section provides the queries available for stations.</div>
                <div className="example-end"></div>
                <div className="example" id="section5">
                <div className="example-title">{info.section5.title}</div>
                <div className="example-text">{info.section5.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=xml"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section5.end}</div>
            </div>
            <div className="example" id="section6">
                <div className="example-title">{info.section6.title}</div>
                <div className="example-text">{info.section6.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=csv&state=CA&county=LA"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section6.end}</div>
            </div>
            <div className="example" id="section7">
                <div className="example-title">{info.section7.title}</div>
                <div className="example-text">{info.section7.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&stationdatetype=creationdate&startdate=3/1/2018&enddate=3/31/2018"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end">{info.section7.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line2"><span className="code-import">import</span> java.net.*;</span>
                        <span className="code-line2"><span className="code-import">import</span> java.io.*;</span>
                        <span className="code-line2"><span className="code-command">class</span> Main {'{'};</span>
                        <span className="code-line2">  public <span className="code-import">static</span> void main(String[] <span className="code-var">args</span>) throws <span className="code-import">Exception</span>{' {'}</span>
                        <span className="code-line2">    String <span className="code-var">urlString</span> = <span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&state=CO&stationdatetype=timestamp&date=8/16/2018%2005:00%20AM"</span>;</span>
                        <span className="code-line2">    URL <span className="code-var">url</span> = new URL(urlString);</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// open the url stream, wrap it an a few "readers"</span></span>
                        <span className="code-line2">    BufferedReader <span className="code-var">reader</span> = <span className="code-command">new</span> BufferedReader(new InputStreamReader(url.openStream()));</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// write the output to stdout</span></span>
                        <span className="code-line2">    String <span className="code-var">line</span>;</span>
                        <span className="code-line2">    while ((<span className="code-var">line</span> = reader.readLine()) != <span className="code-command">null</span>)</span>
                        <span className="code-line2">{'    {'}</span>
                        <span className="code-line2">      System.out.println(<span className="code-var">line</span>);</span>
                        <span className="code-line2">{'    }'}</span>
                        <span className="code-line2"> </span>
                        <span className="code-line2">    <span className="code-comment">// close our reader</span></span>
                        <span className="code-line2">    reader.close();</span>
                        <span className="code-line2">{'  }'}</span>
                        <span className="code-line2">{'}'}</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            </div>
        </div>
    )
}

function Python(){
    return(
        <div className="api">
            <div className="sidebar">
                <div style={{width:"100%",marginBottom:"8px"}}><a
                    className="api-link"
                    href="#reports"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Reports</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section1"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section2"
                >Report Type</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section3"
                >{'Date & Time'}</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section4"
                >Location</a></div>
                <div style={{width:"100%",marginBottom:"8px",marginTop:"8px"}}><a
                    className="api-link"
                    href="#stations"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Stations</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section5"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section6"
                >Location</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section7"
                >Station Date Type</a></div>
            </div>            
            <div className="introduction">Python</div>
            <div className="introduction-text">{info.introduction.python}</div>
            <div className="introduction-divider"></div>
            <div className="introduction" style={{fontSize:"60px"}} id="reports">Reports</div>
            <div className="introduction-text">This section provides the queries available for reports.</div>
            <div className="example-end"></div>
            <div className="example" id="section1">
                <div className="example-title">{info.section1.title}</div>
                <div className="example-text">{info.section1.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=XML"</span>)
                        </span>
                    </pre>
                </div>
                <div className="example-end">{info.section1.end}</div>
            </div>
            <div className="example" id="section2">
                <div className="example-title">{info.section2.title}</div>
                <div className="example-text">{info.section2.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=csv&reporttype=hail"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section2.end}</div>
            </div>
            <div className="example" id="section3">
                <div className="example-title">{info.section3.title}</div>
                <div className="example-text">{info.section3.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&startdate=4/01/2011&enddate=4/30/2011"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011%208:00%20AM"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&timesingmt=true"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text4}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&dtf=2"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            <div className="example" id="section4">
                <div className="example-title">{info.section4.title}</div>
                <div className="example-text">{info.section4.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2018&enddate=5/31/2018&country=usa&state=GA,AL"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                {/* <div className="example-end">{info.section4.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <code className="language-java">
                        {`cURL -o [filename] http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2019"&"enddate=5/31/2019"&"sw=32.33","-97.56"&"ne=32.23","96.31`}
                        </code>
                    </pre>
                </div> */}
                <div className="example-end">{info.section4.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?date=6/1/2019&stations=CO-LR-273,CO-LR-284"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end"></div>
                <div className="introduction" style={{fontSize:"60px"}} id="stations">Stations</div>
                <div className="introduction-text">This section provides the queries available for stations.</div>
                <div className="example-end"></div>
                <div className="example" id="section5">
                <div className="example-title">{info.section5.title}</div>
                <div className="example-text">{info.section5.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=xml"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section5.end}</div>
            </div>
            <div className="example" id="section6">
                <div className="example-title">{info.section6.title}</div>
                <div className="example-text">{info.section6.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=csv&state=CA&county=LA"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section6.end}</div>
            </div>
            <div className="example" id="section7">
                <div className="example-title">{info.section7.title}</div>
                <div className="example-text">{info.section7.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&stationdatetype=creationdate&startdate=3/1/2018&enddate=3/31/2018"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end">{info.section7.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line"><span className="code-import">import</span> urllib2</span>
                        <span className="code-line">
                            <span className="code-var">content</span> = urllib2.urlopen(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&state=CO&stationdatetype=timestamp&date=8/16/2018%2005:00%20AM"</span>)
                        </span>
                        <span className="code-line"><span className="code-var">organized</span> = [line.strip().split(",") for line in content]</span>
                        <span className="code-line"><span className="code-var">header</span> = organized[0]</span>
                        <span className="code-line"><span className="code-var">reports</span> = organized[1:]</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            </div>
        </div>
    )
}

function R(){
    return(
        <div className="api">
            <div className="sidebar">
                <div style={{width:"100%",marginBottom:"8px"}}><a
                    className="api-link"
                    href="#reports"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Reports</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section1"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section2"
                >Report Type</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section3"
                >{'Date & Time'}</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section4"
                >Location</a></div>
                <div style={{width:"100%",marginBottom:"8px",marginTop:"8px"}}><a
                    className="api-link"
                    href="#stations"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Stations</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section5"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section6"
                >Location</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section7"
                >Station Date Type</a></div>
            </div>            
            <div className="introduction">R</div>
            <div className="introduction-text">{info.introduction.r}</div>
            <div className="introduction-divider"></div>
            <div className="introduction" style={{fontSize:"60px"}} id="reports">Reports</div>
            <div className="introduction-text">This section provides the queries available for reports.</div>
            <div className="example-end"></div>
            <div className="example" id="section1">
                <div className="example-title">{info.section1.title}</div>
                <div className="example-text">{info.section1.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=xml"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section1.end}</div>
            </div>
            <div className="example" id="section2">
                <div className="example-title">{info.section2.title}</div>
                <div className="example-text">{info.section2.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=csv&reporttype=hail"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section2.end}</div>
            </div>
            <div className="example" id="section3">
                <div className="example-title">{info.section3.title}</div>
                <div className="example-text">{info.section3.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&startdate=4/01/2011&enddate=4/30/2011"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011%208:00%20AM"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&timesingmt=true"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text4}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&dtf=2"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            <div className="example" id="section4">
                <div className="example-title">{info.section4.title}</div>
                <div className="example-text">{info.section4.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2018&enddate=5/31/2018&country=usa&state=GA,AL"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                {/* <div className="example-end">{info.section4.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <code className="language-java">
                        {`cURL -o [filename] http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2019"&"enddate=5/31/2019"&"sw=32.33","-97.56"&"ne=32.23","96.31`}
                        </code>
                    </pre>
                </div> */}
                <div className="example-end">{info.section4.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportreports.aspx?date=6/1/2019&stations=CO-LR-273,CO-LR-284"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end"></div>
                <div className="introduction" style={{fontSize:"60px"}} id="stations">Stations</div>
                <div className="introduction-text">This section provides the queries available for stations.</div>
                <div className="example-end"></div>
                <div className="example" id="section5">
                <div className="example-title">{info.section5.title}</div>
                <div className="example-text">{info.section5.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=xml"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section5.end}</div>
            </div>
            <div className="example" id="section6">
                <div className="example-title">{info.section6.title}</div>
                <div className="example-text">{info.section6.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=csv&state=CA&county=LA"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section6.end}</div>
            </div>
            <div className="example" id="section7">
                <div className="example-title">{info.section7.title}</div>
                <div className="example-text">{info.section7.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&stationdatetype=creationdate&startdate=3/1/2018&enddate=3/31/2018"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end">{info.section7.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">X</span> {'<-'} read.csv(url(<span className="code-url">"http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&state=CO&stationdatetype=timestamp&date=8/16/2018%2005:00%20AM"</span>))
                        </span>
                        <span className="code-line">print(<span className="code-var">X</span>)</span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            </div>
        </div>
    )
}

function MATLAB(){
    return(
        <div className="api">
            <div className="sidebar">
                <div style={{width:"100%",marginBottom:"8px"}}><a
                    className="api-link"
                    href="#reports"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Reports</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section1"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section2"
                >Report Type</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section3"
                >{'Date & Time'}</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section4"
                >Location</a></div>
                <div style={{width:"100%",marginBottom:"8px",marginTop:"8px"}}><a
                    className="api-link"
                    href="#stations"
                    style={{color:"black",fontSize:"25px",paddingLeft:"20px"}}
                >Stations</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section5"
                >File Format</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section6"
                >Location</a></div>
                <div className="api-link-div"><a
                    className="api-link"
                    href="#section7"
                >Station Date Type</a></div>
            </div>            
            <div className="introduction">MATLAB</div>
            <div className="introduction-text">{info.introduction.matlab}</div>
            <div className="introduction-divider"></div>
            <div className="introduction" style={{fontSize:"60px"}} id="reports">Reports</div>
            <div className="introduction-text">This section provides the queries available for reports.</div>
            <div className="example-end"></div>
            <div className="example" id="section1">
                <div className="example-title">{info.section1.title}</div>
                <div className="example-text">{info.section1.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=xml'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section1.end}</div>
            </div>
            <div className="example" id="section2">
                <div className="example-title">{info.section2.title}</div>
                <div className="example-text">{info.section2.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?format=csv&reporttype=hail'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section2.end}</div>
            </div>
            <div className="example" id="section3">
                <div className="example-title">{info.section3.title}</div>
                <div className="example-text">{info.section3.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&startdate=4/01/2011&enddate=4/30/2011'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011%208:00%20AM'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&timesingmt=true'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section3.text4}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?reporttype=hail&date=4/01/2011&dtf=2'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            <div className="example" id="section4">
                <div className="example-title">{info.section4.title}</div>
                <div className="example-text">{info.section4.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2018&enddate=5/31/2018&country=usa&state=GA,AL'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                {/* <div className="example-end">{info.section4.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <code className="language-java">
                        {`cURL -o [filename] http://data.cocorahs.org/cocorahs/export/exportreports.aspx?startdate=5/01/2019"&"enddate=5/31/2019"&"sw=32.33","-97.56"&"ne=32.23","96.31`}
                        </code>
                    </pre>
                </div> */}
                <div className="example-end">{info.section4.text3}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportreports.aspx?date=6/1/2019&stations=CO-LR-273,CO-LR-284'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end"></div>
                <div className="introduction" style={{fontSize:"60px"}} id="stations">Stations</div>
                <div className="introduction-text">This section provides the queries available for stations.</div>
                <div className="example-end"></div>
                <div className="example" id="section5">
                <div className="example-title">{info.section5.title}</div>
                <div className="example-text">{info.section5.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=xml'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section5.end}</div>
            </div>
            <div className="example" id="section6">
                <div className="example-title">{info.section6.title}</div>
                <div className="example-text">{info.section6.text}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportstations.aspx?format=csv&state=CA&county=LA'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section6.end}</div>
            </div>
            <div className="example" id="section7">
                <div className="example-title">{info.section7.title}</div>
                <div className="example-text">{info.section7.text1}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&stationdatetype=creationdate&startdate=3/1/2018&enddate=3/31/2018'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end">{info.section7.text2}</div>
                <div className="example-code">
                    <pre className="code-container">
                        <span className="code-line">
                            <span className="code-var">block</span> = webread(<span className="code-url">'http://data.cocorahs.org/cocorahs/export/exportstations.aspx?country=usa&state=CO&stationdatetype=timestamp&date=8/16/2018%2005:00%20AM'</span>);
                        </span>
                        <span className="code-line"><span className="code-var">block</span></span>
                    </pre>
                </div>
                <div className="example-end"></div>
            </div>
            </div>
        </div>
    )
}

export {CURL,Java,Python,MATLAB,R}